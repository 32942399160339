    body {
      margin: 0;
      padding: 0;
    }

    .container {
      display: flex;
      justify-content: center;
    }

    .content {
      padding: 20px;
    }

    @media (min-width: 768px) {
      .container {
        justify-content: space-between;
      }

      .content {
        flex-basis: 50%;
      }

      .sidebar {
        flex-basis: 25%;
      }
    }
    .paddingT {
        height: 30px;
    }

    .box {
        background-color:  lightgoldenrodyellow;
        border: solid;

        border-color: lightcoral;
        border-radius: 30px;
        padding: 10px;
    }
    video {
  width: 100%;
  height: auto;
}

.special video::-webkit-media-controls {
  display: none;
}