.App {
  text-align: center;
  background-color: #fdf8f1;
  font-family: 'Times New Roman', Times, serif;
}


/* Default styling with space on both sides of hyphen */
.nav-separator {
  margin: 0 5px; /* Adjust the value (5px) to control the amount of space */
}

/* Media query to adjust layout for smaller screens */
@media (max-width: 768px) {
  .nav-separator {
    display: block; /* Make hyphens stack vertically */
    margin: 5px 0; /* Add vertical spacing */
  }
}


.App-header {
  padding-top: 10px;
  text-align: center;
  background-color: #fdf8f1;
  font-family: 'Times New Roman', Times, serif;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  color: black;
  width: 100%;
  min-width: 100%;
}
link .App-header {

  color: black;
}

body {
  margin: 0;
  padding: 0;
}